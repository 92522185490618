export const validateLoginForm = (initialState, setErr) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const idPattern = /^[a-zA-Z0-9_-]+$/;
    const passwordPattern = /^.{8,}$/;

    const adminId = initialState.adminId.trim();

    if (!(emailPattern.test(adminId) || idPattern.test(adminId))) {
        setErr({ global: "Invalid Admin ID or Email" });
        return;
    }

    if (!passwordPattern.test(initialState.password)) {
        setErr({ global: "Invalid Password" });
        return;
    }
    return true;
};