import React from 'react';
import { useAuth } from '../../context/useAuth';
import { Navigate, useLocation } from 'react-router-dom';
import { capitalize } from 'lodash';

export const UserProtected = ({ element }) => {
    const { authState } = useAuth();
    const location = useLocation();
    const url = location.pathname.split("/")[1];
    if (authState?.user && authState?.token && (authState?.user?.pages?.includes(capitalize(url)) || url === "")) {
        return <>{element}</>;
    }

    return <Navigate to={"/login"} replace />;
};

export const AuthProtected = ({ element }) => {
    const { authState } = useAuth();
    if (authState?.user && authState?.token) {
        return <Navigate to={"/"} replace />;
    }

    return <>{element}</>;
};