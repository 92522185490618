import { axiosAuthorized } from "../../api/config"

export const getSecondBanners = async () => {
  try {
    const response = await axiosAuthorized.get(`/bannersingle`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addSecondBanner = async (single_banner_image, id = null) => {
  try {
    const requestData = {
      single_banner_image 
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/singlebanner/update_singlebanner/${id}`
      : `/singlebanner/add_singlebanner`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteSecondBanner = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/singlebanner/delete_singlebanner/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getWebBanners = async () => {
  try {
    const response = await axiosAuthorized.get(`/slider/get_slider`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addWebBanners = async (web_banner, id = null) => {
  try {
    const requestData = {
      web_banner
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/slider/updatewebbanner/${id}`
      : `/slider/create_slider`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteWebBanners = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/slider/delete_slider/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getAppBanners = async () => {
  try {
    const response = await axiosAuthorized.get(`/mobilebanner/get_mobilebanner`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addAppBanners = async (mobile_banner, id = null) => {
  try {
    const requestData = {
      mobile_banner
    };

    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/mobilebanner/updatemobilebanner/${id}`
      : `/mobilebanner/create_mobilebanner`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteAppBanners = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/mobilebanner/delete_mobilebanner/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}