import React, { useMemo } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import Table from "../Common/Table/Table";
import { deleteHospital } from "../../utils/hospitalUtils/hospitalUtils";

const HospitalTable = ({
  datas,
  setEditData,
  setOpen,
  setHospitalsFn,
  user,
  currentPage,
  limit = 10,
}) => {
  const startIndex = (currentPage - 1) * limit + 1; 

  const tableHeadings = useMemo(() => {
    const defaultHeadings = [
      "SL No",
      "Name",
      "Type",
      "Hospital-ID",
      "Email",
      "Phone",
    ];
    const userHasEditAccess = user?.access?.includes("Edit");
    const userHasDeleteAccess = user?.access?.includes("Delete");

    const headings = [...defaultHeadings];

    if (userHasEditAccess) {
      headings.push("Edit");
    }

    if (userHasDeleteAccess) {
      headings.push("Delete");
    }
    return headings;
  }, [user]);

  const delHospital = (hospitalId) => {
    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) return;
    deleteHospital(hospitalId)
      .then((res) => {
        setHospitalsFn();
      })
      .catch((error) => console.error(error));
  };

  return (
    <Table headings={tableHeadings}>
      {datas?.map((data, index) => (
        <tr key={`${data?._id}-${index}`}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {startIndex + index} 
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex items-center gap-4">
              <div className="flex-shrink-0 h-9 w-9 justify-center items-center border rounded-full flex">
                <img
                  className="rounded-full h-9 w-9 object-cover"
                  src={data?.profile_image}
                  alt="lab_profile"
                />
              </div>
              <div className="text-sm font-medium text-gray-900">
                {data?.hospital_name}
              </div>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">{data?.type}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 ">
              {data?.hospital_id}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">
              {data?.email_id}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">
              {data?.phone_number}
            </div>
          </td>
          <td
            className={`${
              !user?.access.includes("Edit") && "hidden"
            } px-6 py-4 whitespace-nowrap `}
          >
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                setEditData(data);
                setOpen(true);
              }}
            >
              <CiEdit color="green" />
            </div>
          </td>
          <td
            className={`${
              !user?.access.includes("Delete") && "hidden"
            } px-6 py-4 whitespace-nowrap `}
          >
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                delHospital(data?._id);
              }}
            >
              <MdOutlineDeleteOutline color="red" />
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
};

export default HospitalTable;
