import React, { useCallback, useEffect, useState } from 'react'
import { NoDataFound, SearchInput } from '../Common';
import { CiHospital1 } from 'react-icons/ci';
import { getCTAIcons } from '../../utils/CTAIconsUtils/CTAIconsUtils';
import CTAIconsTable from './CTAIconsTable';
import CTAIconModal from './CTAIconModal';
import { useAuth } from "../../context/useAuth";

const CTAIconsMainSection = () => {
  const [datas, setDatas] = useState([ ]);
    const setDatasFn = useCallback(() => {
        getCTAIcons().then(res => setDatas(res.data.data)).catch(error => console.error(error));
  }, []);
  useEffect(() => {
    setDatasFn();
  }, [setDatasFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredDatas] = useState([]);
  useEffect(() => {
    const filteredData = datas?.filter((data) => {
      const nameMatch = data?.cta_icon_name
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      return nameMatch;
    });
    setFilteredDatas(filteredData);
  }, [datas, searchQuery]);
    const [editData,setEditData] = useState(null);
  const [open, setOpen] = useState(false);
   const user = useAuth().authState.user;
  return (
    <>
      {open && (
        <CTAIconModal
          setOpen={setOpen}
          setEditData={setEditData}
          setDatasFn={setDatasFn}
          editData={editData}
        />
      )}
      <div className=" flex w-full items-center gap-5 flex-col">
        <div className="flex w-fit h-fit text-xl font-semibold flex-shrink-0">
          CTA Icons
        </div>
        <div className="flex w-full h-full justify-center items-center">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search by Name, Type"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full p-3 flex-col">
        <div className="flex w-full justify-between items-center h-fit">
          <span className="font-semibold text-base">Informations</span>
          <button
            style={{
              background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
            }}
            disabled={!user.access.includes("Edit")}
            className={` text-white ${
              !user.access.includes("Edit") ? "hidden" : "flex"
            } text-sm rounded-md gap-2 p-2   items-center`}
            onClick={() => setOpen(true)}
          >
            <span>
              <CiHospital1 />
            </span>
            <span>Icon</span>
          </button>
        </div>
        {datas?.length > 0 ? (
          <CTAIconsTable
            datas={filteredData}
            setEditData={setEditData}
            setDatasFn={setDatasFn}
            setOpen={setOpen}
            user={user}
          />
        ) : (
          <div className="flex mt-10">
            <NoDataFound data={"CTA Icons"} />
          </div>
        )}
      </div>
    </>
  );
}

export default CTAIconsMainSection