import React, { useCallback, useEffect, useState } from 'react'
import { NoDataFound, SearchInput } from '../Common'
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import AddAdminModal from './AddAdminModal';
import AdminsTable from './AdminsTable';
import { getAdmins } from '../../utils/adminUtils/adminUtils';
import { useAuth } from "../../context/useAuth";

const AdminsMainSection = () => {
   const user = useAuth().authState.user;
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [admins, setAdmins] = useState([]);
  const setAdminsFn = useCallback(() => {
    getAdmins().then(res => setAdmins(res.data.data)).catch(err => console.log(err));
  }, []);
  useEffect(() => { setAdminsFn() }, [setAdminsFn]);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = admins?.filter((amdin) => {
      const nameMatch = amdin?.name?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      const idMatch = amdin?.admin_id?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      const phoneMatch = amdin?.phone_number?.toString()?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      const emailMatch = amdin?.email_id?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      return nameMatch || idMatch || phoneMatch || emailMatch;
    });
    setFilteredData(filteredData);
  }, [admins, searchQuery]);
  return (
    <>
      {open && (
        <AddAdminModal
          setEditData={setEditData}
          editData={editData}
          setOpen={setOpen}
          setAdminsFn={setAdminsFn}
        />
      )}
      <div className=" flex w-full items-center gap-5 flex-col">
        <div className="flex w-fit h-fit text-xl font-semibold">Admins</div>
        <div className="flex w-full h-full justify-center items-center">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search by Name, Id"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full p-3 flex-col ">
        <div className="flex w-full justify-between items-center h-fit">
          <span className="font-semibold text-base">Informations</span>
          <button
            style={{
              background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
            }}
            disabled={!user?.is_master}
            className={` text-white   text-sm rounded-md ${
              user?.is_master ? "flex" : "hidden"
            } gap-2 p-2 items-center`}
            onClick={() => setOpen(true)}
          >
            <MdOutlineAdminPanelSettings />
            <span>Admin</span>
          </button>
        </div>
        {filteredData.length > 0 ? (
          <AdminsTable
            setEditData={setEditData}
            setOpen={setOpen}
            user={user}
            admins={filteredData}
            setAdminsFn={setAdminsFn}
          />
        ) : (
          <div className="flex w-full justify-center items-center">
            <NoDataFound data={"admins"} />
          </div>
        )}
      </div>
    </>
  );
}

export default AdminsMainSection