import React from 'react'
import MainBanner from './MainBanner';
import BannerImages from './BannerImages';
import MediDocsCounts from './MediDocsCounts';
import OurServices from './OurServices';
import OurSpecialitiesDoctor from './OurSepecialitiesDoctors';
import OurSpecialities from './OurSpecialities';
import HospitalNetwork from './HospitalNetwork';
import OurPartners from './OurPartners';
import NewsAndArticles from './NewsAndArticles';
import Faq from './Faq';
import Advertise from './Advertise';
import Cards from './Cards';
import DoctorVideos from './DoctorVideos';
import PatientVideos from './PatientVideos';
import TechnologyVideos from './TechnologyVideos';
import DoctorSlider from './DoctorSlider';
import HealthCards from './HealthCards';
import About from './About';
import OfferSlider from './OfferSlider';
import TitlesAndDescriptions from './TitleAndDescriptions';
import MetaTitleDescriptionTags from './MetaTitleDescriptionTags';
import WebBanners from './WebBanners';
import AppBanners from './AppBanners';

const HomeMainSection = () => {
  return (
    <>
      <div className=" flex w-full items-center gap-5 md:gap-0 flex-col">
        <div className="flex w-full h-fit text-xl font-semibold">
          Home Page Media
        </div>
        <div className="flex flex-col w-full mt-5 gap-5">
          <MetaTitleDescriptionTags/>
          {/* <MainBanner /> */}
          <TitlesAndDescriptions/>
          {/* <BannerImages /> */}
          <WebBanners/>
          <AppBanners/>
          <About/>
          <DoctorSlider />
          {/* <OfferSlider /> */}
          <MediDocsCounts />
          {/* <OurServices /> */}
          {/* <Cards /> */}
          <OurSpecialities />
          <OurSpecialitiesDoctor />
          <HospitalNetwork />
          <OurPartners />
          <DoctorVideos />
          <PatientVideos />
          <TechnologyVideos />
          <NewsAndArticles />
          <Faq />
          {/* <Advertise /> */}
          {/* <HealthCards /> */}
        </div>
      </div>
    </>
  );
}

export default HomeMainSection