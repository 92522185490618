import { Layout } from './layout';
import { Navigate, Route, Routes } from 'react-router-dom';
import { About, Admins, Blogs, Branches, Services, CTAIcons, Career, Departments, Doctors, Home, HospitalListing, Hospitals, Labs, Login, MediDocsNumbers, Refer, Sales, SalesForms, Treatments, Vendors, UserDashboard, Users, Leads } from './pages';
import { AuthProtected, UserProtected } from './components/ProtectedRoutes/UserProtected';
import { useAuth } from './context/useAuth';

function App() {
   const user = useAuth().authState.user;
  return (
    <Routes>
      <Route path="/" element={<UserProtected element={<Layout />} />}>
        <Route index={true} element={<Hospitals/>}/>
        <Route path='/home' element={<Home/>}/>
        <Route path='/hospital-listing' element={<HospitalListing/>}/>
        <Route path='/doctors' element={<Doctors/>}/>
        <Route path='/users' element={<Users/>}/>
        <Route path='/departments' element={<Departments/>}/>
        <Route path='/treatments' element={<Treatments/>}/>
        <Route path='/labs' element={<Labs/>}/>
        <Route path='/vendors' element={<Vendors />}/>
        <Route path='/services' element={<Services />} />
        <Route path="/leads" element={<Leads />} />
        <Route path='/user-dashboard' element={<UserDashboard />}/>
        <Route path='/refer' element={<Refer/>} />
        <Route path='/sales' element={<Sales/>} />
        <Route path='/sales-forms' element={<SalesForms/>} />
        <Route path='/branches' element={<Branches/>} />
        <Route path='/numbers' element={<MediDocsNumbers/>} />
        <Route path='/cta-icons' element={<CTAIcons/>} />
        <Route path='/blogs' element={<Blogs/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/career' element={<Career/>} />
        <Route path="/admins" element={user?.is_master ? <Admins /> : <Navigate to={"/"} />} />
      </Route>
      <Route path="/login" element={<AuthProtected element={<Login />} />} />
    </Routes>
  );
}

export default App;
